import * as React from 'react'
import { Link } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Seo from '../../components/seo'
import Layout from '../../components/layout'

import ShareForm from '../../components/common/share-form'

const Birdcall = () => {
  return (
    <Layout>
      <Seo title="Eat Meati™ X Birdcall" />
      <div className="container-narrower">
        <header className="py-2">
          <StaticImage
            src="../../images/restaurants/meati-x-birdcall.png"
            width={1600}
            quality={95}
            formats={['AUTO', 'WEBP']}
            alt=""
            placeholder="none"
            layout="fullWidth"
            className="mx-1 mx-md-2"
          />
        </header>
        <div className="birdcall-landing px-2 py-2 py-md-3 mb-2 bg-warning rounded-5 text-center me-12">
          <h1 className="large-welcome font-swish fst-italic fw-bold mb-1">Hey, New Neighbor</h1>
          <p className="mb-0 fs-3">We're coming to Birdcall Scottsdale &mdash; soon you can have Meati™ whenever you crave it.</p>
        </div>
        <div className="birdcall-lunch px-2 px-2 pb-2 py-sm-3 mb-2 bg-primary rounded-5 text-white me-12">
          <Row>
            <Col sm={4}>
              <StaticImage
                src="../../../src/images/restaurants/meati-birdcall-sandwich.png"
                width={800}
                quality={95}
                formats={['AUTO', 'WEBP']}
                alt=""
                placeholder="none"
                layout="constrained"
                className="sandwich mt-n1 mb-1 ms-sm-n6 mb-sm-0"
              />
            </Col>
            <Col sm={8}>
              <h2 className="fst-italic mb-1 fs-1">How Does Lunch On Us Sound?</h2>
              <p className="fs-3 mb-0">Enter your email and we’ll send you the details for free  lunch.</p>
              <ShareForm />
              <small><Link className="text-white" to="/legal/rules/meati-x-birdcall-free-sandwich-promo">Terms and conditions apply</Link></small>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default Birdcall
